import {useEffect} from "react";


export const HomePage = () => {

    useEffect(() => {
        document.title = "GQuote - Home"
    }, []);


    return (
        <div>Home</div>
    )
}
