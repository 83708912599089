import logo from './logo.svg';
import './App.css';
import {Link, Route, Router, Switch, useHistory} from "react-router-dom";
import {HomePage} from "./page/Home.page";
import {TermsPage} from "./page/Terms.page";
import {PrivacyPage} from "./page/Privacy.page";
import { createBrowserHistory } from "history";
import {Nav} from "react-bootstrap";
import {useEffect} from "react";

const history = createBrowserHistory();
function App() {

    useEffect(() => {
        document.title = "GQuote"
    }, []);

  return (
    <div className="App">
        <Router history={history}>
            <div>
                <h1>GQuote - Gestisci la tua Associazione!</h1>
                <Switch>
                    <Route path="/terms">
                        <TermsPage />
                    </Route>
                    <Route path="/privacy">
                        <PrivacyPage />
                    </Route>
                    <Route path="/">
                        <HomePage />
                    </Route>
                </Switch>
            </div>
        </Router>
    </div>
  );
}

export default App;
